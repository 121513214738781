import React, { useEffect, useRef, useState } from 'react';
import Swiper from 'react-id-swiper';
import { gsap } from 'gsap';

const SliderGallery = props => {
  const sliderRef = useRef(null);
  const [swiper, updateSwiper] = useState(null);

  const goToNextSlide = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goToPrevSlide = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  const closeGallerySlider = e => {
    if (e.target.dataset.target === 'popup' || e.code === 'Escape') {
      gsap.to(sliderRef.current, {
        duration: 0.35,
        opacity: 0,
        ease: 'power0.easeNone',
        onComplete() {
          props.setShowSlider(false);
          updateSwiper(null);
        },
      });
    }
  };

  const params = {
    getSwiper: updateSwiper,
    slidesPerView: 1,
    spaceBetween: 16,
    initialSlide: props.currentSlide,
  };

  useEffect(() => {
    gsap.to(sliderRef.current, {
      duration: 0.35,
      opacity: 1,
      ease: 'power0.easeNone',
    });

    return () => null;
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', closeGallerySlider);

    return () => window.removeEventListener('keydown', closeGallerySlider);
  }, []);

  return (
    <div
      ref={sliderRef}
      className="slider-gallery"
      onClick={closeGallerySlider}
      data-target="popup"
    >
      <button
        className="slider__close btn p-0 d-flex align-items-center"
        onClick={closeGallerySlider}
        data-target="popup"
      >
        <span
          className="text-small font-weight-medium"
          style={{ pointerEvents: 'none' }}
        >
          CLOSE
        </span>
        <i
          className="fal fa-times ml-3 text-danger"
          aria-hidden="true"
          style={{ pointerEvents: 'none' }}
        ></i>
      </button>

      <div className="slider__content">
        <Swiper {...params}>
          {props.images.map((image, index) => (
            <div className="swiper-slide" key={index}>
              <div className="slider__image">
                <img className="img-fluid" src={image.file.publicURL} alt="" />
              </div>
            </div>
          ))}
        </Swiper>

        <div className="slider__controls">
          <button
            className="btn btn-primary btn-rounded"
            onClick={goToPrevSlide}
          >
            <i className="far fa-long-arrow-left" aria-hidden="true"></i>
          </button>

          <button
            className="btn btn-primary btn-rounded"
            onClick={goToNextSlide}
          >
            <i className="far fa-long-arrow-right" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SliderGallery;
