import React from 'react';

//Components
import Intro from '../../components/intro';
import Gallery from '../../components/gallery';

const GalleryPageTemplate = ({ data, cmsPreview, lang }) => {
  return (
    <>
      <Intro data={data.intro} />

      <Gallery images={data.gallery} />
    </>
  );
};

export default GalleryPageTemplate;
