import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';

//Components
import Image from './image';
import SliderGallery from './slider-gallery';

const Gallery = ({ images }) => {
  let currentRowIdx = 0;
  const IMAGES_PER_ROW = 5;
  const structuredData = images.reduce((acc, image, index) => {
    let data;

    if (index > 0 && index % IMAGES_PER_ROW === 0) {
      currentRowIdx += 1;
    }

    if (index % 10 === 0 || index % 10 === 9) {
      data = {
        ...acc,
        [currentRowIdx]: {
          largeImage: image.file,
          smallImages: acc[currentRowIdx] ? acc[currentRowIdx].smallImages : [],
        },
      };
    } else {
      data = {
        ...acc,
        [currentRowIdx]: {
          largeImage: acc[currentRowIdx] ? acc[currentRowIdx].largeImage : '',
          smallImages: acc[currentRowIdx]
            ? [...acc[currentRowIdx].smallImages, image.file]
            : [image.file],
        },
      };
    }

    return data;
  }, {});

  //Animate
  const galleryRef = useRef(null);

  useEffect(() => {
    if (galleryRef) {
      gsap.from(galleryRef.current, {
        duration: 0.525,
        delay: 0.6,
        opacity: 0,
        yPercent: 15,
        force3D: true,
        ease: 'power2.easeOut',
      });
    }
  }, [galleryRef]);

  //Slider configs
  const [showSlider, setShowSlider] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const openGallerySlider = index => {
    setShowSlider(true);
    setCurrentSlide(index);
  };

  return (
    <>
      <section ref={galleryRef} className="section-gallery py-7 py-md-17">
        <div className="container">
          {Object.keys(structuredData).map((row, rowIndex) => {
            const galleryLargeImageIndex =
              rowIndex % 2 === 0
                ? (rowIndex > 0 ? rowIndex - 1 : rowIndex) * 10
                : (rowIndex > 1 ? rowIndex - 1 : rowIndex) * 10 - 1;

            return (
              <div className="row mx-n3 mb-2" key={rowIndex}>
                <div
                  className={`col-6 px-3 ${
                    rowIndex % 2 === 0 ? 'order-1' : 'order-2'
                  }`}
                >
                  {structuredData[rowIndex].largeImage && (
                    <button
                      className="section__large-image"
                      onClick={() => openGallerySlider(galleryLargeImageIndex)}
                    >
                      <Image
                        image={structuredData[rowIndex].largeImage}
                        alt=""
                      />
                    </button>
                  )}
                </div>

                <div
                  className={`col-6 px-3 ${
                    rowIndex % 2 === 0 ? 'order-2' : 'order-1'
                  }`}
                >
                  <div className="row mx-n3">
                    {structuredData[rowIndex].smallImages.map(
                      (image, index) => {
                        let gallerySmallImageIndex;

                        if (rowIndex % 2 !== 0) {
                          gallerySmallImageIndex = rowIndex * 5 + index;
                        }

                        if (rowIndex % 2 === 0) {
                          gallerySmallImageIndex = rowIndex * 5 + index + 1;
                        }

                        if (rowIndex === 0) {
                          gallerySmallImageIndex = index + 1;
                        }

                        return (
                          <div className="col-6 px-3 mb-4" key={index}>
                            <button
                              className="section__image"
                              onClick={() =>
                                openGallerySlider(gallerySmallImageIndex)
                              }
                            >
                              <Image image={image} alt="" />
                            </button>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      {showSlider && (
        <SliderGallery
          images={images}
          currentSlide={currentSlide}
          setShowSlider={setShowSlider}
        />
      )}
    </>
  );
};

export default Gallery;
